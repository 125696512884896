// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    /* background-color: var(--background--primary); */
}

.increment {
    background-color: var(--background--button--primary);
    color: var(--text-color--white);
}

.decrement {
    background-color: var(--background--button--secondary);
    color: var(--text-color--white);
}

/* Loader style*/
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--background--button--secondary);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: fixed;
    top: 50%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.home-container {
    display: flex;
    justify-content: center;
    height: 70vh;
    align-items: center;
}

.home-img {
    object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kDAAkD;AACtD;;AAEA;IACI,oDAAoD;IACpD,+BAA+B;AACnC;;AAEA;IACI,sDAAsD;IACtD,+BAA+B;AACnC;;AAEA,gBAAgB;AAChB;IACI,WAAW;IACX,YAAY;IACZ,sDAAsD;IACtD,gCAAgC;IAChC,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,sCAAsC;IACtC,eAAe;IACf,QAAQ;AACZ;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".App {\r\n    text-align: center;\r\n    /* background-color: var(--background--primary); */\r\n}\r\n\r\n.increment {\r\n    background-color: var(--background--button--primary);\r\n    color: var(--text-color--white);\r\n}\r\n\r\n.decrement {\r\n    background-color: var(--background--button--secondary);\r\n    color: var(--text-color--white);\r\n}\r\n\r\n/* Loader style*/\r\n.loader {\r\n    width: 48px;\r\n    height: 48px;\r\n    border: 5px solid var(--background--button--secondary);\r\n    border-bottom-color: transparent;\r\n    border-radius: 50%;\r\n    display: inline-block;\r\n    box-sizing: border-box;\r\n    animation: rotation 1s linear infinite;\r\n    position: fixed;\r\n    top: 50%;\r\n}\r\n\r\n@keyframes rotation {\r\n    0% {\r\n        transform: rotate(0deg);\r\n    }\r\n\r\n    100% {\r\n        transform: rotate(360deg);\r\n    }\r\n}\r\n\r\n.home-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    height: 70vh;\r\n    align-items: center;\r\n}\r\n\r\n.home-img {\r\n    object-fit: contain;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    max-width: max-content;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #F8F8FF;
}

.login-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container label {
    margin-bottom: 5px;
    font-weight: bold;
    padding: 1rem;
}

.login-container input {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.login-container .error {
    color: red;
    margin-bottom: 15px;
}

.login-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.login-container button:hover {
    background-color: #0056b3;
}

.login-section {
    display: flex;
    justify-content: center;
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/Login.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".login-container {\r\n    max-width: max-content;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n    background-color: #F8F8FF;\r\n}\r\n\r\n.login-container h2 {\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.login-container form {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.login-container label {\r\n    margin-bottom: 5px;\r\n    font-weight: bold;\r\n    padding: 1rem;\r\n}\r\n\r\n.login-container input {\r\n    margin-bottom: 15px;\r\n    padding: 8px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 3px;\r\n}\r\n\r\n.login-container .error {\r\n    color: red;\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.login-container button {\r\n    padding: 10px;\r\n    background-color: #007bff;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 3px;\r\n    cursor: pointer;\r\n}\r\n\r\n.login-container button:hover {\r\n    background-color: #0056b3;\r\n}\r\n\r\n.login-section {\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

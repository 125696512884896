import React from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import './App.css';
import Header from './components/header/Header';
import PrivateRoute from './components/auth/PrivateRoute';
import Login from './components/auth/Login';
import Home from './Home';
import { Search } from './components/search/Search';
import { authenticateUser, logoutUser } from './utilities/utility';

function App() {

  const navigate = useNavigate();

  const onLogoutClicked = () => {
    logoutUser()
    navigate('/');
  }
  return (
    <div className="App">
      <Header isLoggedIn={authenticateUser()} onLogout={onLogoutClicked}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </div>
  );
}

export default App;

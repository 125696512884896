import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'
import { USER, PASS } from '../../utilities/constants'

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setTimeout(() => {
                const isValid = username === USER && password === PASS;
                if (isValid) {
                    // Store the authentication token or user data
                    localStorage.setItem('isLoggedIn', 'TRUE');
                    // Navigate to the protected route
                    navigate('/search');
                } else {
                    // Handle authentication error
                    setError('Invalid username or password');
                }
            }, 1000)

        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred during login. Please try again later.');
        }
    };

    return (
        <section className='login-section'>
            <div className='login-container'>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            id="user"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder='Enter your username'
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            id="pass"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Enter your password'
                        />
                    </div>
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    <button type="submit">Login</button>
                </form>
            </div>
        </section>
    );
};

export default Login;

import React from 'react';
import StarRating from './StarRating'
import { truncateText } from '../../utilities/utility'
import { PRODUCT_PAGE_URL } from '../../utilities/constants'

interface Product {
    ProductId: number
    ProductName: string;
    ProductPrice: number;
    ProductDescription: string;
    RatingCount: string;
    AverageRating: string;
}

interface CardListProps {
    products: Product[];
}

const redirectToDetailsPage = (id: number) => {
    window.open(`/${PRODUCT_PAGE_URL}/${id}`, "_blank")
}

const CardList: React.FC<CardListProps> = ({ products }) => {
    return (
        <div className='card-list'>
            {products && products.map((product, index) => (
                <div className='product-card' key={index} onClick={() => redirectToDetailsPage(product.ProductId)}>
                    <p style={{ fontWeight: 'bold' }}>{product.ProductName}</p>
                    <div className='ratings'>
                        <StarRating rating={product.AverageRating} total_reviews={product.RatingCount} />
                    </div>
                    <p>${product.ProductPrice}</p>
                    <p>Product ID: {product.ProductId}</p>
                    {
                        product.ProductDescription ? <>
                            <p title={product.ProductDescription}>{truncateText(product.ProductDescription, 80)}</p>
                        </>
                            :
                            <></>
                    }
                </div>
            ))}
        </div>
    );
};

export default CardList;

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { SearchIcon } from '../../assets/search';
import CardList from './CardList';
import { authenticateUser } from '../../utilities/utility';
import { BASE_URL } from '../../utilities/constants';
import './Search.css';


export function Search() {
    const navigate = useNavigate();
    // const count = useAppSelector((state) => state.counter.value)
    // const dispatch = useAppDispatch()
    const [query, setQuery] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [tradionalData, setTradionalData] = useState<any>(null);
    const [knowledgeData, setKnowledgeData] = useState<any>(null);

    useEffect(() => {
        if (!authenticateUser()) navigate('/login');
    }, []);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const fetchData = async (text: any) => {
        setLoader(true)
        try {
            const tradionalAPI = fetch(`${BASE_URL}/api/traditionalSearch?productName=${text}`);
            const knowledgeAPI = fetch(`${BASE_URL}/api/knowledgeSearch?productName=${text}`);
            Promise.all([tradionalAPI, knowledgeAPI])
                .then(responses => Promise.all(responses.map(res => res.json())))
                .then((data: any) => {
                    setTradionalData(data[0]?.products)
                    setKnowledgeData(data[1]?.products)
                    setTimeout(() => {
                        setLoader(false)
                    }, 500)
                })
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoader(false)
        }
    };

    const getKnowledgeData = async (text: string) => {
        setLoader(true)
        try {
            fetch(`${BASE_URL}/knowledgeSearch?productName=${text}`)
                .then(response => {
                    if (!response.ok) {
                        setLoader(false)
                        if (response.status === 400) {
                            throw new Error('Bad request - 400');
                        } else if (response.status === 401) {
                            throw new Error('Unauthorized - 401');
                        } else if (response.status === 404) {
                            throw new Error('Not found - 404');
                        } else if (response.status === 500) {
                            throw new Error('Internal server error - 500');
                        } else {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                    }
                    return response.json()
                })
                .then(data => {
                    // console.log(data);
                    setKnowledgeData(data.products)
                    setTimeout(() => {
                        setLoader(false)
                    }, 900)
                })
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false)
        }
    }

    const getTraditionalData = async (text: string) => {
        setLoader(true)
        try {
            fetch(`${BASE_URL}/traditionalSearch?productName=${text}`)
                .then(response => {

                    if (!response.ok) {
                        setLoader(false)
                        if (response.status === 400) {
                            throw new Error('Bad request - 400');
                        } else if (response.status === 401) {
                            throw new Error('Unauthorized - 401');
                        } else if (response.status === 404) {
                            throw new Error('Not found - 404');
                        } else if (response.status === 500) {
                            throw new Error('Internal server error - 500');
                        } else {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                    }
                    return response.json()
                })
                .then(data => {
                    // console.log(data);
                    setTradionalData(data.products)
                    setTimeout(() => {
                        setLoader(false)
                    }, 900)
                })
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false)
        }
    }

    const onSearch = async () => {
        setKnowledgeData([])
        setTradionalData([])
        try {
            // await getTraditionalData(query)
            // await getKnowledgeData(query)
            await fetchData(query)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const handleKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    return (
        <div className='search-page-container'>
            <section className='search-section'>
                <div className='search-box-container'>
                    <input type="text" placeholder="Search DaaX.AI" value={query} onChange={handleOnChange} onKeyUp={handleKeyUp} />
                    <SearchIcon onClick={onSearch} className='search-icon' />
                </div>
            </section>
            {
                loader ? <div><span className="loader"></span></div>
                    :
                    <section className='result-section'>
                        {
                            tradionalData && knowledgeData &&
                            <>
                                <div className='result-container'>
                                    <div className='result-items'>
                                        <h3>Traditional Search Results ({tradionalData.length}) </h3>
                                        {
                                            tradionalData.length > 0 ? <div className='item-container'>
                                                <CardList products={tradionalData} />
                                            </div>
                                                :
                                                <EmptyData />
                                        }
                                    </div>
                                    <div className='card-list-border'></div>
                                    <div className='result-items'>
                                        <h3>AI Knowledge Search Results ({knowledgeData.length})</h3>
                                        {
                                            knowledgeData.length > 0 ? <div className='item-container'>
                                                <CardList products={knowledgeData} />
                                            </div>
                                                :
                                                <EmptyData />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </section>
            }
        </div>
    )
}


function EmptyData() {
    return (
        <div className='result-empty-container'>
            <p>No Results Found</p>
        </div>
    )
}
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'
import brandLogo from '../../assets/logo-transparent-png.png'

interface HeaderProps {
    isLoggedIn: Boolean;
    onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, onLogout }) => {
    return (
        <header>
            <div className='brand'>
                <img src={brandLogo} alt="Logo" className='brand-img'/>
                {/* <span style={{ fontSize: '1.5rem' }}>Company Name</span> */}
            </div>
            <div>
                {isLoggedIn? (
                    <button className='logout-btn' onClick={onLogout}>Logout</button>
                ) : (
                    <Link to="/login" className='login-btn'>Login</Link>
                )}
            </div>
        </header>
    );
};

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    background-color: #F8F8FF;
    color: #ecf0f1;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand {
    display: flex;
    align-items: center;
}

.login-btn {
    color: #ecf0f1;
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: #3498db;
    border-radius: 5px;
}

.logout-btn {
    background-color: #e74c3c;
    color: #ecf0f1;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer
}

.brand-img {
    height: 32px;
    margin-right: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,oBAAoB;IACpB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;IAClB;AACJ;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["header {\r\n    background-color: #F8F8FF;\r\n    color: #ecf0f1;\r\n    padding: 1rem;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.brand {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.login-btn {\r\n    color: #ecf0f1;\r\n    text-decoration: none;\r\n    padding: 0.5rem 1rem;\r\n    background-color: #3498db;\r\n    border-radius: 5px;\r\n}\r\n\r\n.logout-btn {\r\n    background-color: #e74c3c;\r\n    color: #ecf0f1;\r\n    padding: 0.5rem 1rem;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer\r\n}\r\n\r\n.brand-img {\r\n    height: 32px;\r\n    margin-right: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

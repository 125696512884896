import { createSlice } from '@reduxjs/toolkit'
import { USER, PASS } from '../../utilities/constants' 

// Define a type for the slice state
interface AuthSlice {
    userDetails: { user: string, password: string },
    isValid: Boolean
}

// Define the initial state using that type
const initialState: AuthSlice = {
    userDetails: { user: '', password: '' },
    isValid: false
}

export const authSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        login: (state, action: any) => {
            const inputs = action.payload;
            state.isValid = inputs.user === USER && inputs.pass === PASS
        }
    },
})

// Action creators are generated for each case reducer function
export const { login } = authSlice.actions

export default authSlice.reducer
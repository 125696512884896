import React from 'react';
import StarSolid from '../../assets/star-solid.svg'
import StarRegular from '../../assets/star-regular.svg'
import StarHalfSolid from '../../assets/star-half-stroke-solid.svg'

const StarRating: React.FC<{ rating: string, total_reviews: string }> = ({ rating, total_reviews }) => {
    let localRating: number = parseFloat(rating);
    let localCount: number = parseFloat(total_reviews);
    const fullStars = Math.floor(localRating);
    const hasHalfStar = localRating % 1 !== 0;

    const renderStars = () => {
        const stars = [];

        for (let i = 1; i <= fullStars; i++) {
            stars.push(<img key={`full_star_${i}`} src={StarSolid} alt="none" width={15}></img>); // Full star
        }

        if (hasHalfStar) {
            stars.push(<img key='half_star_' src={StarHalfSolid} alt="none" width={15}></img>); // Half star
        }

        const emptyStars = 5 - stars.length;
        for (let i = 1; i <= emptyStars; i++) {
            stars.push(<img key={`empty_star_${i}`} src={StarRegular} alt="Empty Star" width={15} />);
        }

        return stars;
    };

    return (
        <div>
            {renderStars()}
            <span className='total-product-review'>
                {' '}({localCount})
            </span>
        </div>
    )
};

export default StarRating;

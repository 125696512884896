import React from 'react';
import './App.css';
import Logo from './assets/logo-png.png'

function Home() {
    return (
        <section className='home-section'>
            <div className="home-container">
                <img className="home-img" alt="home-page-logo" src={Logo} style={{ width: '60vw', height: '90vh' }} />
            </div>
        </section>
    );
}

export default Home;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
/*Background colors*/
    --background--primary: #e8e8e8;
    --background--secondary: #f4f4f4;

    --background--button--primary: #0085ff;
    --background--button--secondary: #aa3507;
  
/*Text color*/
    --text-color--regular: #202020;
    --text-color--secondary: #7a7a7a;
    --text-color--white: #fff;

/*Spacing*/
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 12px;
    --spacing-lg: 16px;

    --spacing-xl: 24px;
    --spacing-2xl: 32px;
    --spacing-3xl: 40px;
    --spacing-4xl: 48px;

    --spacing-5xl: 64px;
}

body {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;AACA,oBAAoB;IAChB,8BAA8B;IAC9B,gCAAgC;;IAEhC,sCAAsC;IACtC,wCAAwC;;AAE5C,aAAa;IACT,8BAA8B;IAC9B,gCAAgC;IAChC,yBAAyB;;AAE7B,UAAU;IACN,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;;IAElB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;;IAEnB,mBAAmB;AACvB;;AAEA;IACI,SAAS;AACb","sourcesContent":[":root {\r\n/*Background colors*/\r\n    --background--primary: #e8e8e8;\r\n    --background--secondary: #f4f4f4;\r\n\r\n    --background--button--primary: #0085ff;\r\n    --background--button--secondary: #aa3507;\r\n  \r\n/*Text color*/\r\n    --text-color--regular: #202020;\r\n    --text-color--secondary: #7a7a7a;\r\n    --text-color--white: #fff;\r\n\r\n/*Spacing*/\r\n    --spacing-xs: 4px;\r\n    --spacing-sm: 8px;\r\n    --spacing-md: 12px;\r\n    --spacing-lg: 16px;\r\n\r\n    --spacing-xl: 24px;\r\n    --spacing-2xl: 32px;\r\n    --spacing-3xl: 40px;\r\n    --spacing-4xl: 48px;\r\n\r\n    --spacing-5xl: 64px;\r\n}\r\n\r\nbody {\r\n    margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
